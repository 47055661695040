p {
  display: block;
  margin-block-end: 1em;
  margin-block-start: 1em;
  margin-bottom: 1rem;
  margin-inline-end: 0px;
  margin-inline-start: 0px;
  margin-top: 0;
  unicode-bidi: isolate;
}
