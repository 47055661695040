// Alleva root colors (global css vars).
// Keep this file in sync with `.\_colors.scss`.

@import 'colors';

// Global CSS vars. Useful for passing around in TS/JS as their
// names are available verbatim at run time for use. Will also
// be useful for dynamic theming in the future.
:root {
  // Theme colors
  --alleva-blue: #{$alleva-blue};
  --alleva-blue-dark: #{$alleva-blue-dark};
  --alleva-blue-light: #{$alleva-blue-light};
  --alleva-orange: #{$alleva-orange};
  --alleva-orange-dark: #{$alleva-orange-dark};
  --alleva-orange-light: #{$alleva-orange-light};
  --alleva-purple: #{$alleva-purple};

  // Uncommon variants
  --orange-dark: #{$orange-dark};

  // Primary colors
  --green: #{$green};
  --red: #{$red};
  --blue: #{$blue};
  --yellow: #{$yellow};

  // Primary variants
  --red-dark: #{$red-dark};
  --red-light: #{$red-light};
  --green-light: #{$green-light};
  --green-dark: #{$green-dark};
  --blue-lighter: #{$blue-lighter};
  --blue-light: #{$blue-light};
  --blue-dark: #{$blue-dark};
  --yellow-light: #{$yellow-light};
  --yellow-dark: #{$yellow-dark};

  // Grayscale variants
  --gray-lightest: #{$gray-lightest};
  --gray-lighter: #{$gray-lighter};
  --gray-light: #{$gray-light};
  --gray-mid-lighter: #{$gray-mid-lighter};
  --gray-mid-light: #{$gray-mid-light};
  --gray-mid: #{$gray-mid};
  --gray-mid-dark: #{$gray-mid-dark};
  --gray-mid-darker: #{$gray-mid-darker};
  --gray-dark: #{$gray-dark};
  --gray-darker: #{$gray-darker};
  --gray-darkest: #{$gray-darkest};
  --black: #{$black};
  --white: #{$white};
}
