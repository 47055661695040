@import 'colors';

// Ensure that items don't wrap in table "Actions" columns.
.cdk-column-actionsTemplate {
  white-space: nowrap;
}

.mat-error {
  // Custom "error" styles for progress spinner.
  &.mat-mdc-progress-spinner circle {
    stroke: $red;
  }
}

.mat-success {
  // Custon "success" style for icons.
  &.mat-icon {
    color: $green;
  }
  // Custom "success" styles for progress spinner.
  &.mat-mdc-progress-spinner circle {
    stroke: $green;
  }
}

// Custom white background for the Material CDK overlay container.
.white-background .mat-mdc-dialog-container {
  background-color: $white;
}

// Add default "primary" theme colors to the material button toggle group.
.mat-button-toggle-checked {
  background-color: $alleva-blue;

  button,
  button > span {
    color: $white;
  }
}

// Material icons - Shared attributes (DRY)
mat-icon[class*='material-icons'] {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
