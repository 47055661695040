@import 'fonts';
@import 'colors';
@import 'mixins';

// Global `body` styles.

body {
  font-family: $font-family-default;
  font-size: $font-size-base;
  background-color: $gray-mid-light;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

// @future Potentially include for the main scrollbar.
// @note Does not work well with accessibility (thin).
// html { @include custom-scrollbar; }

@media print {
  body {
    background-color: $white;
  }
}
