@use 'sass:math';

@import 'breakpoints';

@mixin set-columns-css($total-columns: 12, $gutter: 16px) {
  @for $i from 1 through $total-columns {
    .col-#{$i} {
      $width: calc(100% / #{$total-columns} * #{$i} - #{$gutter});
      flex: 0 0 calc(100% / #{$total-columns} * #{$i} - #{$gutter});
      max-width: calc(100% / #{$total-columns} * #{$i} - #{$gutter});
      padding: math.div($gutter, 2);
    }
  }
}

// Responsive breakpoints
@mixin set-responsive-columns($total-columns: 12, $gutter: 16px) {
  // SM
  @media (min-width: getBreakpoint(sm)) {
    @include set-columns-css($total-columns, $gutter);
  }
  // MD
  @media (min-width: getBreakpoint(md)) {
    @include set-columns-css($total-columns, $gutter);
  }
  // LG
  @media (min-width: getBreakpoint(lg)) {
    @include set-columns-css($total-columns, $gutter);
  }
  // XL
  @media (min-width: getBreakpoint(xl)) {
    @include set-columns-css($total-columns, $gutter);
  }
}

// Include the responsive columns
@include set-responsive-columns(12, 16px);
