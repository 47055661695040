// Reusable sass font variables (global).

$font-color-default: var(--alleva-blue-dark);
$font-size-base: 16px;

$font-family-fallback: 'Roboto', sans-serif;
$font-family-default: 'Metric-Regular', $font-family-fallback;
$font-family-bold: 'Metric-Bold', $font-family-fallback;
$font-family-display: 'Financier-Regular', $font-family-fallback;
$font-family-national: 'National-Regular', $font-family-fallback;

$font-xs: 0.8rem;
$font-sm: 0.9rem;
$font-md: 1rem;
$font-lg: 1.125rem;
$font-xl: 1.25rem;

$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;
