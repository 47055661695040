@import 'colors';
@import 'functions';

// Z indexes
$zindex-content: 1;
$zindex-navbar: 998;
$zindex-header: 998;
$zindex-quick-launch: 998;
$zindex-dialog: 999;
$zindex-mat-cdk: 1000;
$zindex-tiny-mce-floating-toolbar: 1000;
$zindex-loader-backdrop: 1001;
$zindex-mat-autocomplete: 1001;

// Layout elements sizing
$footer-height: 54px;
$header-height: 54px;
$navbar-scrollbar-width: 4px;
$navbar-width: 230px;
$navbar-width-collapsed: 56px;

// Form sizes
$field-height: 2.5rem;
$field-min-width: 8rem;

// Form colors
$field-background-color-disabled: $gray-light;
$field-background-color-hover: $white;
$field-background-color: $white;
$field-border-color-disabled: rgba(0, 0, 0, 0.06);
$field-border-color-error: $red;
$field-border-color-hover: $gray-darker;
$field-border-color: $gray-mid-dark;
$field-text-color: $black;
$field-icon-disabled: $gray-mid-dark;

// Link colors
$link-blue: $blue;
$link-blue-hover: $blue-dark;

// Tabs
$tab-background-color: hexToRGBA($gray-mid-light, 0.8);
$tab-max-height: 3rem;
