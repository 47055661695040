// Reusable sass colors (global).
// Keep this file in sync with `.\root.scss`.

// Theme colors
$alleva-blue: #3f4e65;
$alleva-blue-dark: #0f2832;
$alleva-blue-light: #cbe5f9;
$alleva-orange: #ff8c6f;
$alleva-orange-dark: #c75301;
$alleva-orange-light: #ffddd4;
$alleva-purple: #7365e3;

// Uncommon variants
$orange-dark: #996e00;

// Primary colors (RGBY)
$red: #a94442;
$green: #327e36;
$blue: #3c94d8;
$yellow: #e5de00;

// Primary variants
$red-dark: #8f2d2b;
$red-light: #e5c7c6;
$green-light: #a4d3a4;
$green-dark: #245c27;
$blue-lighter: #d6d2f8;
$blue-light: #67abe1;
$blue-dark: #3076ad;
$yellow-light: #f5f5c6;
$yellow-dark: #ccc500;

// Grayscale variants
$gray-lightest: #fafafa;
$gray-lighter: #f5f5f5;
$gray-light: #f0f0f0;
$gray-mid-lighter: #ebebeb;
$gray-mid-light: #e5e5e5;
$gray-mid: #a4a1a1;
$gray-mid-dark: #9e9e9e;
$gray-mid-darker: #8e8e8e;
$gray-dark: #585858;
$gray-darker: #212121;
$gray-darkest: #1f1f1f;
$black: #000000;
$white: #ffffff;
