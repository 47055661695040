@use 'sass:math';

@import 'fonts';
@import 'functions';
@import 'mixins';
@import 'variables';

// Root color overrides
:root {
  // General text.
  --mdc-theme-text-primary-on-background: #{$field-text-color};
  // Input field font color.
  --mdc-outlined-text-field-input-text-color: #{$field-text-color};
  // Disabled input field font color.
  --mdc-outlined-text-field-disabled-input-text-color: #{$field-text-color};
  // Input field label font color.
  --mdc-outlined-text-field-label-text-color: #{$field-text-color};
  // Disabled input field label font color.
  --mdc-outlined-text-field-disabled-label-text-color: #{$field-text-color};
  // Disabled dropdown trigger font color.
  --mat-select-disabled-trigger-text-color: #{$field-text-color};
  // Active button toggle element background colors.
  --mat-standard-button-toggle-selected-state-background-color: #{$alleva-blue};
}

// CDK Overlay
.cdk-overlay-container {
  // Set default z-index for the CDK overlay. Should be `1000` as per the
  // default, but let's make it distinct with a variable so we can easily
  // compare z-indexes with others in `_variables.scss`.
  z-index: $zindex-mat-cdk;

  // Styling for the custom Material dropdown "Clear All" option.
  .clear-select-all-container {
    display: flex;
    justify-content: center;
    align-items: center;

    button.clear-dropdown-option,
    button.select-all-dropdown-option {
      align-items: center;
      border-radius: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      text-align: center;
      width: 100%;

      &:hover {
        background-color: rgba($red, 0.1);
      }

      > mat-pseudo-checkbox {
        display: none;
        visibility: hidden;
      }

      > span,
      > span > alleva-icon > mat-icon {
        line-height: 1.5rem;
        margin: 0;
      }

      &:disabled > span > alleva-icon > mat-icon {
        opacity: 0.25;
      }
    }

    // If both buttons are present, they should take 50% of the width each
    button.clear-dropdown-option,
    button.select-all-dropdown-option {
      flex: 1 1 50%;
      width: 50%;
    }
  }

  // Remove the default padding for the CDK overlay container for the
  // `alleva-client-status-update` component.
  div[role='listbox'] {
    &:has(.status-update-active),
    &:has(.status-update-inactive),
    &:has(.status-update-suspended),
    &:has(.status-update-discharged) {
      padding: 0;
    }
  }

  // Override the default Material styles for the `mat-option` component
  // regarding the status update options in 'alleva-client-status-update'.
  mat-option {
    $not-selected: '.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple)';

    &.status-update-active,
    &.status-update-active#{$not-selected} {
      span.label,
      alleva-icon > mat-icon {
        color: $green;
      }
    }

    &.status-update-inactive,
    &.status-update-inactive#{$not-selected} {
      span.label,
      alleva-icon > mat-icon {
        color: $gray-dark;
      }
    }

    &.status-update-suspended,
    &.status-update-suspended#{$not-selected} {
      span.label,
      alleva-icon > mat-icon {
        color: $red;
      }
    }

    &.status-update-discharged,
    &.status-update-discharged#{$not-selected} {
      span.label,
      alleva-icon > mat-icon {
        color: $orange-dark;
      }
    }

    &.status-update-potential,
    &.status-update-potential#{$not-selected} {
      span.label,
      alleva-icon > mat-icon {
        color: $alleva-blue;
      }
    }
  }
}

// Disable the body scroll when a dialog or fullscreen loader is open.
body:has(alleva-loading-indicator.full-screen),
body:has(alleva-dialog) {
  overflow: hidden;
}

// Ensure all material elements with the "hidden" attribute are not displayed.
mat-select[hidden],
mat-option[hidden] {
  display: none;
  visibility: hidden;
}

// Override Angulars default Material Autocomplete styles. Use a custom panel
// class to ensure the z-index and other styles are set correctly in the CDK.
.alleva-autocomplete-panel {
  z-index: $zindex-mat-autocomplete;

  * {
    z-index: $zindex-mat-autocomplete;
  }
}

// Override Angulars white text for accent badges.
.mat-badge.mat-badge-accent > .mat-badge-content {
  color: $font-color-default;
}

// Large badges should not exceed 22px in height and width per AM-387.
.mat-badge-large {
  $tab-badge-size: 22px;

  // Update sizes.
  .mat-badge-content {
    font-size: 14px;
    height: $tab-badge-size;
    line-height: 1.25rem;
    width: $tab-badge-size;
  }

  // Account for new positions of the badge due to the size change.
  &.mat-badge-after .mat-badge-content {
    right: -#{$tab-badge-size};
    top: -#{math.div($tab-badge-size, 2)};
  }
}

// Radio color overrides
.mat-mdc-radio-button.mat-primary {
  // Disabled radio button font color.
  --mat-radio-disabled-label-color: $font-color-default;
}

// Set default padding for the material nav-list base.
mat-nav-list.mat-mdc-list-base {
  padding-top: 0;
}

// Custom Material form field styles.
mat-form-field {
  &.mat-mdc-form-field {
    // Set the min-width for all Material forms.
    min-width: $field-min-width;

    &:has(input),
    &:has(mat-select) {
      .mat-mdc-text-field-wrapper {
        height: $field-height;
        max-height: $field-height;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
        .mat-mdc-form-field-flex {
          height: $field-height;

          mat-datepicker-toggle > button {
            height: $field-height;
            padding: 0;
            width: $field-height;
          }

          .mat-mdc-form-field-infix {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            height: 100%;
            min-height: $field-height;
            padding: 0;

            mat-select,
            .mat-mdc-select-trigger,
            input {
              min-height: $field-height;
            }

            input {
              height: $field-height;
            }
          }
        }
      }

      // The position of the label in an empty field.
      .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
        top: 50%;
      }

      // The label position when the field is the `outlined` variant and focused.
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
        .mat-mdc-form-field-flex .mdc-floating-label--float-above {
          --mat-mdc-form-field-label-transform: translateY(
              -#{calc($field-height/1.5)}
            )
            scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
        }
      }
    }

    // Custom prefix icon styles for all Material forms.
    .prefix-icon-container {
      align-items: center;
      background-color: $field-background-color;
      border-bottom-left-radius: 4px;
      border-right: 1px solid $field-border-color;
      border-top-left-radius: 4px;
      box-sizing: border-box;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      padding: 0 0.25rem;
      width: 1.75rem;

      alleva-icon {
        scale: 0.85;
      }
    }

    // Custom material form field styles for textareas.
    textarea.mat-mdc-input-element {
      // Text areas are scalable, lets make sure we dont allow scaling down
      // to a point where the first line of text is unreadable.
      min-height: 2rem;
    }

    // Per UX update form subscripts should have this fixed height, adjust here.
    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align::before {
      height: 1.5625rem;
    }
  }
  // Per custom UX requirements for the default Angular Material field wrappers,
  // update the default background colors for inputs and their disabled states.
  .mat-mdc-text-field-wrapper {
    background-color: $field-background-color;

    &.mdc-text-field--disabled {
      background-color: $field-background-color-disabled;

      .prefix-icon-container {
        background-color: $field-background-color-disabled;
        border-right: 1px solid $field-border-color-disabled;
      }
    }

    &.mdc-text-field--invalid {
      .prefix-icon-container {
        border-right: 1px solid $field-border-color-error;
      }
    }
  }
}

// Hide Material subscript/hint box when component is configured with
// no hint present (i.e. `hideHint` is true for custom fields).
.hide-hint-subscript div.mat-mdc-form-field-subscript-wrapper {
  display: none;
  visibility: hidden;
}

// Add ability to new-line in Material tooltips when using `\n`.
.mdc-tooltip {
  white-space: pre-line;
}

// Add ellipsis to Angular Material hints for text that wraps instead of
// wrapping it. This keeps form hints consistent in spacing with no overlap.
// Also add a "help" cursor when hovering over the hint to go along with its
// title attribute already in place to further emphasize the hint to the user.
mat-hint {
  background-color: transparent;
  cursor: help;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// For Angular Material dropdown menu panels.
// @see "https://material.angular.io/components/menu"
.mat-mdc-menu-panel {
  // Set the max-width for the notifications menu panel.
  &.notifications-menu {
    max-width: 360px;
    overflow: visible;
    width: 360px;
  }

  // Remove padding, we'll control this ourselves directly in the components
  // that use `man-menu`.
  > .mat-mdc-menu-content {
    padding: 0;
  }
}

// Center `mat-option` text when an icon is present.
mat-option.has-icon {
  justify-content: center;

  > span.mdc-list-item__primary-text {
    display: inline-block;
    margin-right: 0;
  }
}

// Override dropdown group label
.cdk-overlay-container {
  .mat-mdc-optgroup-label {
    font-weight: bold;
    text-decoration: underline;
  }
}

// Override default Material styles for the `alleva-button` component.
alleva-button {
  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0;
  }

  button {
    font-size: inherit;
  }

  &.inline .mat-mdc-button-touch-target {
    line-height: inherit;
    width: inherit;
    height: inherit;
  }

  // When a button houses a custom icon size, the icon and the
  // parent containers should inherit the padding.
  &.custom-icon-size {
    > button,
    .mat-fab span.mat-button-wrapper,
    .icon-button span.mat-button-wrapper,
    .mat-mini-fab span.mat-button-wrapper {
      padding: inherit;
      width: inherit;
      height: inherit;
      line-height: normal;
    }
  }

  > button.alleva-button {
    box-shadow: none;
    overflow: hidden;

    // Override the default Material button font color for the "accent" variant.
    &:not(:disabled).mat-accent > span {
      color: $font-color-default;
    }

    &,
    &:active,
    &:disabled,
    &:disabled:active,
    &:disabled:focus,
    &:disabled:focus:active,
    &:disabled:hover,
    &:focus,
    &:focus:active,
    &:hover,
    &.mdc-fab.mdc-ripple-upgraded--background-focused,
    &.mdc-fab:not(.mdc-ripple-upgraded):focus {
      box-shadow: none;
      overflow: hidden;
    }
  }
}

// Override default Material styles for the `alleva-client-portal-communications`
// component.
alleva-client-portal-communications {
  // Restrict vertical sizing of the button group to fit the inside the parent
  // element (the "tab title" in this case).
  mat-button-toggle-group {
    mat-button-toggle.mat-button-toggle,
    mat-button-toggle.mat-button-toggle > button {
      height: inherit;
      max-height: inherit;

      span.mat-button-toggle-label-content {
        line-height: inherit;
      }
    }
  }
}

// Override default Material styles for the `alleva-icon` component.
alleva-icon {
  mat-icon svg {
    vertical-align: top;
  }
}

// Override default Material styles for the following inputs when they contain
// a "prefix" icon.
alleva-input,
alleva-input-arm-position,
alleva-input-blood-pressure,
alleva-input-currency,
alleva-input-dropdown,
alleva-input-height,
alleva-input-temperature,
alleva-input-weight {
  &:has(.prefix-icon-container) {
    mat-form-field {
      span.input-container {
        height: 100%;
        width: 100%;
        padding-left: 1rem;
      }

      &.height-value.in span.input-container {
        padding-left: 0.5rem;
      }

      .mat-mdc-text-field-wrapper {
        padding-left: 0;
        height: 100%;

        &:not(.mdc-text-field--disabled) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      // The labels that should use anuglars default floating label padding and
      // not be overridden by the custom padding for the prefix icon.
      $default-floating-labels: '&' + ':not(.diastolic)' + ':not(.position)' +
        ':not(.height-units)' + ':not(.height-value.in)' + ':not(.meridiem)' +
        ':not(.temperature-units)' + ':not(.weight-units)';
      // Do not add any padding to any input containers/labels that are not
      // the first in the row.
      #{$default-floating-labels} {
        span.input-container,
        span.mat-mdc-select-value-text {
          padding-left: 0.5rem;
        }
        // When the input has a "prefix" icon, we need to adjust the padding to
        // account for the icon.
        label.mdc-floating-label {
          padding-left: 1.25rem;

          &.mdc-floating-label--float-above {
            border-radius: 2px;
            padding-left: 0;
            background-color: $field-background-color;
          }
        }
      }
    }
  }
}

$secondary-input-width: 6.75rem;

// Override default Material styles for the `alleva-input-arm-position` component.
alleva-input-arm-position {
  .inputs-container mat-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
    visibility: hidden;
  }

  mat-form-field {
    &.arm {
      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__trailing,
        .mdc-notched-outline .mdc-notched-outline__trailing {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $field-border-color;
          margin-right: 1px;
        }
        &.mdc-text-field--disabled
          .mdc-notched-outline
          .mdc-notched-outline__trailing {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $field-border-color-disabled;
        }
      }
    }

    &.position {
      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__leading,
        .mdc-notched-outline .mdc-notched-outline__leading {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid transparent;
        }
        &.mdc-text-field--disabled {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid $field-border-color-disabled;
        }
      }
    }
  }

  > .mat-mdc-form-field-hint,
  > mat-error {
    padding-left: 1rem;
  }
}

// Override default Material styles for the `alleva-input-blood-pressure` component.
alleva-input-blood-pressure {
  .inputs-container mat-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
    visibility: hidden;
  }

  mat-form-field {
    &.systolic {
      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__trailing,
        .mdc-notched-outline .mdc-notched-outline__trailing {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $field-border-color;
          margin-right: 1px;
        }
        &.mdc-text-field--disabled
          .mdc-notched-outline
          .mdc-notched-outline__trailing {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $field-border-color-disabled;
        }
      }
    }

    &.diastolic {
      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__leading,
        .mdc-notched-outline .mdc-notched-outline__leading {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid transparent;
        }
        &.mdc-text-field--disabled {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid $field-border-color-disabled;
        }
      }
    }
  }

  > .mat-mdc-form-field-hint,
  > mat-error {
    padding-left: 1rem;
  }
}

// Override default Material styles for the `alleva-client-status-update` component.
alleva-client-status-update {
  alleva-input-dropdown .mat-mdc-text-field-wrapper {
    @include default-border-radius;
  }

  // Override default Material styles for the `alleva-input-dropdown` component
  // for the client status updates.
  alleva-input-dropdown {
    &.status-update-active
      mat-form-field.mat-mdc-form-field
      .mat-mdc-text-field-wrapper {
      background-color: $green;

      span.label,
      alleva-icon > mat-icon,
      .mat-mdc-select-arrow {
        color: $white;
      }
    }

    &.status-update-inactive
      mat-form-field.mat-mdc-form-field
      .mat-mdc-text-field-wrapper {
      background-color: $gray-dark;

      span.label,
      alleva-icon > mat-icon,
      .mat-mdc-select-arrow {
        color: $white;
      }
    }

    &.status-update-suspended
      mat-form-field.mat-mdc-form-field
      .mat-mdc-text-field-wrapper {
      background-color: $red;

      span.label,
      alleva-icon > mat-icon,
      .mat-mdc-select-arrow {
        color: $white;
      }
    }

    &.status-update-discharged
      mat-form-field.mat-mdc-form-field
      .mat-mdc-text-field-wrapper {
      background-color: $orange-dark;

      span.label,
      alleva-icon > mat-icon,
      .mat-mdc-select-arrow {
        color: $white;
      }
    }
  }

  // Add disabled state colors to the `alleva-input-dropdown` component for the
  // client status updates.
  &[disabled] alleva-input-dropdown {
    &.status-update-active
      mat-form-field.mat-mdc-form-field
      .mat-mdc-text-field-wrapper {
      background-color: hexToRGBA($green, 0.5);
    }

    &.status-update-inactive
      mat-form-field.mat-mdc-form-field
      .mat-mdc-text-field-wrapper {
      background-color: hexToRGBA($gray-dark, 0.5);
    }

    &.status-update-suspended
      mat-form-field.mat-mdc-form-field
      .mat-mdc-text-field-wrapper {
      background-color: hexToRGBA($red, 0.5);
    }

    &.status-update-discharged
      mat-form-field.mat-mdc-form-field
      .mat-mdc-text-field-wrapper {
      background-color: hexToRGBA($orange-dark, 0.5);
    }
  }
}

// Override default Material styles for the `alleva-input-height` component.
alleva-input-height {
  .inputs-container mat-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
    visibility: hidden;
  }

  mat-form-field {
    &.height-value {
      &.cm,
      &.empty {
        .mdc-text-field--outlined {
          &.mdc-text-field--invalid
            .mdc-notched-outline
            .mdc-notched-outline__trailing,
          .mdc-notched-outline .mdc-notched-outline__trailing {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid $field-border-color;
            margin-right: 1px;
          }
          &.mdc-text-field--disabled
            .mdc-notched-outline
            .mdc-notched-outline__trailing {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid $field-border-color-disabled;
          }
        }
      }
      &.ft {
        .mdc-text-field--outlined {
          &.mdc-text-field--invalid
            .mdc-notched-outline
            .mdc-notched-outline__trailing,
          .mdc-notched-outline .mdc-notched-outline__trailing {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid $field-border-color;
            margin-right: 1px;
          }
          &.mdc-text-field--disabled
            .mdc-notched-outline
            .mdc-notched-outline__trailing {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid $field-border-color-disabled;
          }
        }
      }
      &.in {
        .mdc-text-field--outlined {
          &.mdc-text-field--invalid
            .mdc-notched-outline
            .mdc-notched-outline__leading,
          .mdc-notched-outline .mdc-notched-outline__leading {
            width: 0.25rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0 solid transparent;
          }
          &.mdc-text-field--invalid
            .mdc-notched-outline
            .mdc-notched-outline__trailing,
          .mdc-notched-outline .mdc-notched-outline__trailing {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid $field-border-color;
            margin-right: 1px;
          }
          &.mdc-text-field--disabled
            .mdc-notched-outline
            .mdc-notched-outline__leading {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0 solid $field-border-color-disabled;
          }
          &.mdc-text-field--disabled
            .mdc-notched-outline
            .mdc-notched-outline__trailing {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid $field-border-color-disabled;
          }
        }
      }
    }

    &.height-units {
      &.cm,
      &.empty {
        width: $secondary-input-width;
        max-width: $secondary-input-width;
      }

      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__leading,
        .mdc-notched-outline .mdc-notched-outline__leading {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid transparent;
        }
        &.mdc-text-field--disabled {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid $field-border-color-disabled;
        }
      }
    }
  }

  > .mat-mdc-form-field-hint,
  > mat-error {
    padding-left: 1rem;
  }
}

// Override default Material styles for the `alleva-input-pick-date` component.
alleva-input-pick-date {
  .mdc-text-field {
    border-radius: var(--mdc-shape-small, 4px);
  }

  .mat-mdc-icon-button {
    color: $alleva-orange;

    &:disabled {
      color: $field-icon-disabled;
    }

    > svg {
      vertical-align: text-bottom;
    }
  }

  .mdc-text-field--invalid {
    .mat-mdc-icon-button {
      color: $red;

      &:disabled {
        color: $field-icon-disabled;
      }
    }
  }
}

// Override default Material styles for the `alleva-input-pick-files` component.
alleva-input-pick-files {
  &:not(.disabled) {
    div.mdc-text-field,
    div.mdc-text-field:hover {
      background: transparent;
    }

    mat-label {
      color: $field-text-color;

      &:hover {
        color: $field-text-color;
      }
    }
  }
}

// Override default Material styles for the `alleva-input-pick-time` component.
alleva-input-pick-time {
  // Make sure the suffix icon fits in the input field nicely.
  alleva-button.suffix-icon,
  alleva-button.suffix-icon > button {
    height: $field-height;
    max-height: $field-height;
    max-width: $field-height;
    width: $field-height;
  }
}

// Override default Material styles for the `alleva-input-radio` component.
alleva-input-radio {
  &.disabled {
    mat-radio-button label:hover {
      cursor: default;
    }
  }
  mat-radio-button label {
    cursor: default;

    &:hover {
      cursor: pointer;
    }
  }
  // Keep radio group elements centered vertically.
  > mat-radio-group.mat-mdc-radio-group {
    margin-top: 0;
  }
}

// Override Material styles for the `alleva-input-sign-me` component.
alleva-input-sign-me {
  alleva-input-sign-me-controls > alleva-button > button.mdc-fab {
    // Remove the default box shadows from the canvas control buttons.
    box-shadow: none;
    overflow: hidden;
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }

    // Also update the color of the button to match the other inputs.
    background-color: $gray-mid-darker;

    &:disabled {
      background-color: $gray-mid-lighter;
    }
  }
  alleva-input-sign-me-pin .mdc-text-field {
    padding: 0;

    // Remove the default border for the PIN input fields for the "outlined"
    // form variant
    &.mdc-text-field--outlined .mdc-notched-outline > div {
      border: none;
    }

    .mdc-line-ripple {
      // Remove the default underline for the PIN input fields.
      &::before {
        border-bottom-width: 0;
      }
      // Display, but make smaller the underline for the PIN input fields on
      // focus.
      &::after {
        border-bottom-width: 0.5px;
      }
    }
  }
}

// Override default Material styles for the `alleva-input-temperature` component.
alleva-input-temperature {
  .inputs-container mat-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
    visibility: hidden;
  }

  mat-form-field {
    &.temperature-value {
      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__trailing,
        .mdc-notched-outline .mdc-notched-outline__trailing {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $field-border-color;
          margin-right: 1px;
        }
        &.mdc-text-field--disabled
          .mdc-notched-outline
          .mdc-notched-outline__trailing {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $field-border-color-disabled;
        }
      }
    }

    &.temperature-units {
      width: $secondary-input-width;
      max-width: $secondary-input-width;

      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__leading,
        .mdc-notched-outline .mdc-notched-outline__leading {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid transparent;
        }
        &.mdc-text-field--disabled {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid $field-border-color-disabled;
        }
      }
    }
  }

  > .mat-mdc-form-field-hint,
  > mat-error {
    padding-left: 1rem;
  }
}

alleva-input-toggle {
  mat-slide-toggle {
    &.mat-primary {
      .mdc-switch.mdc-switch--selected {
        &:enabled {
          &:focus:not(:active),
          &:hover:not(:focus):not(:active) {
            .mdc-switch__handle {
              &::after,
              &::before {
                background: $alleva-blue;
              }
            }
          }

          .mdc-switch__handle {
            &::after,
            &::before {
              background: $alleva-blue;
            }

            svg {
              fill: $white;
            }
          }
        }
      }

      .mdc-switch__track {
        background-color: $alleva-blue-light;
      }
    }

    &.mat-accent {
      .mdc-switch.mdc-switch--selected {
        &:enabled {
          &:focus:not(:active),
          &:hover:not(:focus):not(:active) {
            .mdc-switch__handle {
              &::after,
              &::before {
                background: $alleva-orange;
              }
            }
          }

          .mdc-switch__handle {
            &::after,
            &::before {
              background: $alleva-orange;
            }

            svg {
              fill: $black;
            }
          }
        }
      }

      .mdc-switch__track {
        background-color: $alleva-orange-light;
      }
    }

    &.mat-success {
      .mdc-switch.mdc-switch--selected {
        &:enabled {
          &:focus:not(:active),
          &:hover:not(:focus):not(:active) {
            .mdc-switch__handle {
              &::after,
              &::before {
                background: $green;
              }
            }
          }

          .mdc-switch__handle {
            &::after,
            &::before {
              background: $green;
            }

            svg {
              fill: $white;
            }
          }
        }
      }

      .mdc-switch__track {
        background-color: $green-light;
      }
    }

    &.mat-warn {
      .mdc-switch.mdc-switch--selected {
        &:enabled {
          &:focus:not(:active),
          &:hover:not(:focus):not(:active) {
            .mdc-switch__handle {
              &::after,
              &::before {
                background: $red;
              }
            }
          }

          .mdc-switch__handle {
            &::after,
            &::before {
              background: $red;
            }

            svg {
              fill: $white;
            }
          }
        }
      }

      .mdc-switch__track {
        background-color: $red-light;
      }
    }

    .mdc-switch__track {
      background-color: $gray-mid-light;
    }
  }
}

// Override default Material styles for the `alleva-input-weight` component.
alleva-input-weight {
  .inputs-container mat-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
    visibility: hidden;
  }

  mat-form-field {
    &.weight-value {
      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__trailing,
        .mdc-notched-outline .mdc-notched-outline__trailing {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $field-border-color;
          margin-right: 1px;
        }
        &.mdc-text-field--disabled
          .mdc-notched-outline
          .mdc-notched-outline__trailing {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 1px solid $field-border-color-disabled;
        }
      }
    }

    &.weight-units {
      width: $secondary-input-width;
      max-width: $secondary-input-width;

      .mdc-text-field--outlined {
        &.mdc-text-field--invalid
          .mdc-notched-outline
          .mdc-notched-outline__leading,
        .mdc-notched-outline .mdc-notched-outline__leading {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid transparent;
        }
        &.mdc-text-field--disabled {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 solid $field-border-color-disabled;
        }
      }
    }
  }

  > .mat-mdc-form-field-hint,
  > mat-error {
    padding-left: 1rem;
  }
}

// Override default Material styles for the `alleva-menu-item` component.
alleva-menu-item {
  .mat-mdc-list-item-interactive:not(:focus):not(
      .mdc-list-item--disabled
    ):hover::before {
    opacity: 0;
  }

  > alleva-menu-sub-level-item.collapsed
    > a.mat-mdc-list-item
    > span.mdc-list-item__content {
    justify-content: center;
    width: calc(100% - $navbar-scrollbar-width);
  }

  alleva-menu-sub-level-item {
    &:not(.collapsed) > a.mat-mdc-list-item > .mdc-list-item__content {
      padding-left: 1.75rem;
      font-size: $font-sm;
    }
    &.collapsed > a.mat-mdc-list-item > .mdc-list-item__content {
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }

  .mdc-list-item:hover .mdc-list-item__primary-text,
  .mdc-list-item:focus .mdc-list-item__primary-text,
  .mdc-list-item__content .mdc-list-item__primary-text {
    color: $white;
  }

  .mdc-list-item__content > span {
    // Center all contents vertically with flex precision.
    display: flex;
    align-items: center;
  }
}

// Override default Material styles for the `alleva-tabs` component.
alleva-tabs {
  $tab-gray: hexToRGBA($gray-mid-light, 0.8);

  &.vertical {
    > mat-tab-group {
      display: flex;
      flex-direction: row;

      > mat-tab-header {
        border-right: 1px solid $gray-mid;

        .mat-mdc-tab-label-container .mat-mdc-tab-labels {
          flex-direction: column;

          .mdc-tab {
            justify-content: left;
            padding-left: 1rem;
            padding-right: 1rem;
            min-width: 13rem;
          }

          .mdc-tab-indicator--active,
          .mdc-tab-indicator__content--underline {
            border: none;
            background: $tab-gray;
          }
        }
      }
    }
  }

  &:not(.vertical) > mat-tab-group > mat-tab-header {
    border-bottom: 1px solid $gray-mid;
  }

  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
  }

  // Per UX, make the font color for tab title "accent" badges use our dark font.
  span.mat-badge.mat-badge-accent > span {
    color: $alleva-blue-dark;
  }
}

// Override default Material styles for the `alleva-layout-navbar-user-actions` component.
alleva-layout-navbar-user-actions {
  &.is-collapsed alleva-button > .mat-mdc-mini-fab {
    width: 40px;
    height: 40px;
    position: relative;
    box-shadow: none;

    alleva-icon > mat-icon {
      position: relative;
      left: 2px;
      font-size: 1.5rem;
    }
  }
}
