// Since LastPass doesn't adhere to `autocomplete="off"` we have to force it
// hidden when `autocomplete="off"` is set on the input to behave as expected.
// @see https://www.reddit.com/r/Lastpass/comments/12yuwm6/comment/kb64d0f

[autocomplete='off'] {
  + div[data-lastpass-icon-root='true'] {
    display: none;
  }
  + div[data-lastpass-infield='true'] {
    display: none;
  }
}
