/* Global Styles */

// Import text element (p, span, etc) styles
@import 'app/styles/text.scss';

// Import Fonts
@import 'app/styles/font-families.scss';

// Global CSS variables
@import 'app/styles/root.scss';

// Grid System
@import 'app/styles/grid.scss';

// Remaining styles
@import 'app/styles/body.scss';
@import 'app/styles/headings.scss';
@import 'app/styles/print.scss';

// Angular Material
@import 'app/styles/material.scss';

// TinyMCE
@import 'app/styles/tiny-mce.scss';
@import 'app/styles/tiny-mce-overrides.scss';

// Gist Code Snippets
@import 'app/styles/gist.scss';

// Time Picker (ngx-material-timepicker)
@import 'app/styles/time-picker.scss';

// LastPass Password Manager
@import 'app/styles/lastpass.scss';
