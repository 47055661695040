@import 'mixins';
@import 'variables';

// Custom Alleva styling for the TinyMCE `inline` variant.
alleva-input-editor.inline {
  > editor {
    @include default-border-radius;
    background-color: $field-background-color;
    border: 1px solid $field-border-color;
    // Match padding of other form fields regarding the placeholder.
    padding: 1rem;

    &:hover {
      background-color: $field-background-color-hover;
      border: 1px solid $field-border-color-hover;

      > div {
        background-color: $field-background-color-hover;
      }
    }

    &,
    > div {
      background-color: $field-background-color;
      // Set as the same height as a `textarea`.
      min-height: calc($field-height * 2);
    }
  }

  &.disabled,
  &.disabled:hover {
    > editor {
      background-color: $field-background-color-disabled;
      border: 1px solid $field-border-color-disabled;

      > div {
        background-color: $field-background-color-disabled;
        cursor: default;
      }
    }
  }
}
