@import 'variables';

alleva-input-editor editor {
  *:hover {
    cursor: text;
  }

  > div.tox-tinymce {
    border: none;
    border-radius: 0;
  }

  > div.mce-content-body,
  > div.mce-content-body:focus-visible {
    border: none;
    border-radius: 0;
    outline: none;
  }
}

// Make sure the "inline" floating toolbar has a proper z-index to be displayed
// above the other elements including any potential dialog windows.
.tox-tinymce-inline {
  z-index: $zindex-tiny-mce-floating-toolbar;
}

// Override font colors for the TinyMCE editor.
div.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: $field-text-color;
}
