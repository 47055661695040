@import 'variables';

/** FONT IMPORTS */

@font-face {
  font-family: 'Metric-Regular';
  font-style: normal;
  src: url('/assets/fonts/klim/metric-web-regular.woff') format('woff');
}

@font-face {
  font-family: 'Metric-Bold';
  font-style: normal;
  src: url('/assets/fonts/klim/metric-web-bold.eot');
  src:
    url('/assets/fonts/klim/metric-web-bold.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/klim/metric-web-bold.woff2') format('woff2'),
    url('/assets/fonts/klim/metric-web-bold.woff') format('woff');
}

@font-face {
  font-family: 'Financier-Regular';
  font-style: normal;
  src: url('/assets/fonts/klim/financier-display-web-regular.eot');
  src:
    url('/assets/fonts/klim/financier-display-web-regular.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/klim/financier-display-web-regular.woff2')
      format('woff2'),
    url('/assets/fonts/klim/financier-display-web-regular.woff') format('woff');
}

@font-face {
  font-family: 'National-Regular';
  font-style: normal;
  src: url('/assets/fonts/klim/national-web-regular.eot');
  src:
    url('/assets/fonts/klim/national-web-regular.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/klim/national-web-regular.woff2') format('woff2'),
    url('/assets/fonts/klim/national-web-regular.woff') format('woff');
}

// Roboto - Fallback font
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  /* IE9 Compat Modes */
  src: url('/assets/fonts/google/roboto/Roboto-Regular.eot');
  src:
    url('/assets/fonts/google/roboto/Roboto-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/google/roboto/Roboto-Regular.woff2') format('woff2'),
    url('/assets/fonts/google/roboto/Roboto-Regular.woff') format('woff'),
    url('/assets/fonts/google/roboto/Roboto-Regular.ttf') format('truetype'),
    url('/assets/fonts/google/roboto/Roboto-Regular.svg#Roboto') format('svg');
}

// Icons - Default
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/google/material-icons/material-icons.ttf')
    format('truetype');
  // Do not display until ready to use.
  font-display: block;
}
.material-icons {
  font-family: 'Material Icons';
}

// Icons - Outlined
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/google/material-icons/material-icons-outlined.otf')
    format('opentype');
  // Do not display until ready to use.
  font-display: block;
}
.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

// Icons - Rounded
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/google/material-icons/material-icons-round.otf')
    format('opentype');
  // Do not display until ready to use.
  font-display: block;
}
.material-icons-round {
  font-family: 'Material Icons Round';
}

// Icons - Sharp
@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/google/material-icons/material-icons-sharp.otf')
    format('opentype');
  // Do not display until ready to use.
  font-display: block;
}
.material-icons-sharp {
  font-family: 'Material Icons Sharp';
}

// Icons - Two-toned
@font-face {
  font-family: 'Material Icons Two Tone';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/google/material-icons/material-icons-two-toned.otf')
    format('opentype');
  // Do not display until ready to use.
  font-display: block;
}
.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
}
