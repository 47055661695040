@import 'colors';
@import 'variables';

// Custom styled scrollbar mixin
@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: $navbar-scrollbar-width;
  }
  &::-webkit-scrollbar-track {
    background: $white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $alleva-blue;
    border-radius: 0px;
    border: 0px solid $white;
  }
}

@mixin default-border-radius {
  border-radius: 0.25rem;
}

@mixin no-spacing {
  margin: 0;
  padding: 0;
}
